<template>
  <div class="wrapper p-10">
    <div class="flex items-center justify-items-center gap-4">
      <div>
        <div
          class="grid justify-items-center gap-4 border-2 border-color rounded-3xl p-10 max-w-sm"
        >
          <img
            src="https://playda.s3.ap-south-1.amazonaws.com/playda-logos/playda-logo-dark.webp"
            alt="Playda Logo"
            width="220"
            height="96"
          />
          <div class="text-3xl text-nColorYellow font-bold text-center">
            Scan this QR code to get started!
          </div>
          <img
            :src="desktopQR"
            alt="Playda Demo QR Code"
            width="300"
            height="300"
          />
        </div>
      </div>
      <div class="grid justify-items-center gap-4">
        <div class="border-2 border-color rounded-3xl p-6 w-full">
          <div class="flex gap-3 justify-between">
            <div>
              <p class="text-xl text-nColorYellow font-bold">
                How Playda Works
              </p>
              <ul class="text-lg text-white leading-7">
                <li>
                  • <strong>Customer Engagement:</strong> Enhance your brand
                  experience with interactive games.
                </li>
                <li>
                  • <strong>Employee Engagement:</strong> Boost morale and
                  productivity with fun, gamified challenges.
                </li>
                <li>
                  • <strong>Integration:</strong> Seamlessly integrate Playda
                  into your existing platforms
                </li>
              </ul>
            </div>
            <div>
              <img
                src="https://playda.s3.ap-south-1.amazonaws.com/demo/how-playda-works.webp"
                alt="How Playda Works"
                width="150"
                height="150"
              />
            </div>
          </div>
        </div>
        <div class="border-2 border-color rounded-3xl p-6 w-full">
          <div class="flex gap-3 justify-between">
            <div>
              <p class="text-xl text-nColorYellow font-bold">
                Playda in Numbers
              </p>
              <ul class="text-lg text-white leading-7">
                <li>• <strong>Gameplays:</strong> Over 26k+ gameplays.</li>
                <li>
                  • <strong>Game Catalogue:</strong> Access to 16 diverse games
                  across various categories.
                </li>
                <li>
                  • <strong>Stickiness:</strong> 85% user engagement and
                  retention rate.
                </li>
                <li>
                  • <strong>Game Ratings:</strong> Average rating of 4.7/5 stars
                  by players.
                </li>
              </ul>
            </div>
            <div>
              <img
                src="https://playda.s3.ap-south-1.amazonaws.com/demo/playda-in-numbers.webp"
                alt="Playda In Numbers"
                width="150"
                height="150"
              />
            </div>
          </div>
        </div>
        <div class="border-2 border-color rounded-3xl p-6 w-full">
          <div class="flex gap-3 justify-between">
            <div>
              <p class="text-xl text-nColorYellow font-bold">What to Expect</p>
              <ul class="text-lg text-white leading-7">
                <li>
                  • <strong>Categories & List of Games:</strong> Discover a wide
                  range of games suited to different preferences.
                </li>
                <li>
                  • <strong>Leaderboards & Rewards:</strong> Track progress and
                  reward top performers.
                </li>
                <li>
                  • <strong>Coupon Delivery:</strong> Integrate coupon codes to
                  incentivize and reward players.
                </li>
              </ul>
            </div>
            <div>
              <img
                src="https://playda.s3.ap-south-1.amazonaws.com/demo/what-to-expect.webp"
                alt="What To Expect"
                width="150"
                height="150"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  props: {
    desktopQR: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: { ...mapGetters(["isPageLoading"]) },
  methods: {},
  mounted() {},
};
</script>

<style>
.wrapper {
  background-color: #000000;
  height: 100dvh;
}
.border-color {
  border: 1px solid #533c89;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
